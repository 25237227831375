<template>
  <PrimaryMenu />

  <MainHeader />

  <SectionBlock>
    <SectionTitle title="Üdvözlünk a KicsiKocsik elektromos gyerekautó kölcsönző oldalán!" headingLevel="1" />
    <SectionContent>
      <p class="text-center">
        Vállalkozásunk elektromos gyermekautók kölcsönzésével foglalkozik. Kitelepülést vállalunk többek közt családi
        összejövetelekre, céges vagy magánrendezvényekre, óvodákba, KRESZ-parkokba, elérhetővé téve így a vezetés
        élményét bárhol.
      </p>
      <p class="text-center">
        Célunk, hogy minél több kisgyermek megismerkedhessen az elektromos kisautókkal, és kipróbálhassa vezetni azokat.
        Emellett szülőként fontosnak tartjuk, hogy gyermekeinket minél előbb megtanítsuk a közlekedési szabályokra,
        ezért szolgáltatásunkat kiegészítettük egy igény szerint kérhető KRESZ-oktatással, amely során a gyerekek
        megismerkednek a fontosabb közlekedési táblákkal, a jelzőlámpák jelentésével, valamint a főbb szabályokkal.
      </p>
    </SectionContent>
  </SectionBlock>

  <SectionBlock class="bg-light">
    <SectionTitle title="Élje át gyermeked is a vezetés élményét!" />
    <SectionContent>
      <BRow gutterX="4" gutterY="7" alignH="center" alignV="center">
        <BCol sm="4" v-for="question in homeQuestions" :key="question.id">
          <QuestionCard :question="question" />
        </BCol>
      </BRow>
    </SectionContent>
  </SectionBlock>

  <SectionServices />

<!--  <SectionBlock class="bg-light">-->
<!--    <SectionTitle title="Hogyan zajlik az elektromos kisautó kölcsönzés?" />-->
<!--    <SectionContent>-->
<!--      <BRow gutterX="4" gutterY="7" alignH="center" alignV="center" alignContent="stretch">-->
<!--        <BCol cols="12" sm="6" lg="4" xxl="2" v-for="step in howToRentSteps" :key="step.id" alignSelf="stretch">-->
<!--          <StepCard :step="step" />-->
<!--        </BCol>-->
<!--      </BRow>-->
<!--    </SectionContent>-->
<!--    <SectionActionButtons moreTo="/hogyan-zajlik-az-elektromos-kisauto-kolcsonzes" />-->
<!--  </SectionBlock>-->

  <SectionCars />

  <SectionBanner :style="{ 'background-image': `url(${require('@/assets/section-banner-bg.jpg')})` }" />

  <SectionBlog numberOfPosts="3" />

  <SectionContact />

  <Footer />

  <BModal id="home-popup" title="Érkezik az élményvezetés szezonja!" hideFooter size="xl" centered>
    <p>Kedves kis sofőreink!</p>
    <p>
      Lassan érkezik az élményvezetés szezonja, de addig is minden eső mentes napon telefonos bejelentkezés alapján már
      tudtok jönni a hét minden napján.
    </p>
    <p>Várunk szeretettel benneteket, továbbra is változatlan árakkal!</p>
    <p>2000 Ft / 30 perc</p>
  </BModal>

  <BButton id="home-popup-button" v-b-modal.home-popup />
</template>

<script setup>
import PrimaryMenu from "../components/layout/PrimaryMenu";
import MainHeader from "@/components/layout/MainHeader";
import QuestionCard from "@/components/units/cards/QuestionCard";
// import StepCard from "@/components/units/cards/StepCard";
import SectionBanner from "@/components/layout/sections/SectionBanner";
import Footer from "@/components/layout/Footer";
import SectionTitle from "@/components/layout/sections/parts/SectionTitle";
import SectionBlock from "@/components/layout/sections/parts/SectionBlock";
import SectionContent from "@/components/layout/sections/parts/SectionContent";
// import SectionActionButtons from "@/components/layout/sections/parts/SectionActionButtons";
import SectionContact from "@/components/layout/sections/SectionContact";
import SectionServices from "@/components/layout/sections/SectionServices";
import SectionCars from "@/components/layout/sections/SectionCars";
import SectionBlog from "@/components/layout/sections/SectionBlog";
import { onMounted } from "vue";

onMounted(() => {
  const today = new Date().getTime();
  const expDate = new Date("2024-04-01").getTime();

  if (today <= expDate) {
    document.getElementById("home-popup-button").click();
  }
});
</script>

<style lang="scss" scoped>
#home-popup-button {
  display: none;
}
</style>
